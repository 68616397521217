







































































































import { Component, Mixins } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";

import { PREFECTURES } from "@/consts/const";

import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
} from "@/consts/portal";

import {
  PortalOffice,
  PortalOfficeInternalRemark,
  DEFAULT_PORTAL_OFFICE,
  DEFAULT_PORTAL_OFFICE_INTERNAL_REMARK,
} from "@/model/portal";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
  },
})
export default class ContractDetails extends Mixins(AxiosMixin, UtilMixin) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private portalOffice: PortalOffice = { ...DEFAULT_PORTAL_OFFICE };
  private internalRemark: PortalOfficeInternalRemark = {
    ...DEFAULT_PORTAL_OFFICE_INTERNAL_REMARK,
  };
  private showSaveCompleteBar = false;

  private PREFECTURES = PREFECTURES;
  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;

  public async created() {
    await this.fetchItem(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** 契約情報取得 */
  private async fetchItem(id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_office/get",
        { portal_office_id: id },
        (res) => {
          this.portalOffice = res.data.portal_office;
          if (res.data.internal_remark != undefined) {
            this.internalRemark = res.data.internal_remark;
          }
          resolve(0);
        }
      );
    });
  }

  /** 保存ボタンクリック時 */
  private async save(portalOffice: PortalOffice, internalRemark: PortalOfficeInternalRemark) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    internalRemark.portal_office_id = portalOffice.id;
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_office/save",
      {
        portal_office: portalOffice,
        internal_remark: internalRemark,
      },
      (res) => {
        this.portalOffice = res.data.portal_office;
        this.showSaveCompleteBar = true;

        setTimeout(() => {
          this.$router.push({
            path: `/portal/portal-office-ibow/detail/${portalOffice.id}`,
          });
        }, 2000);
      }
    );
  }

  // キャンセルボタンクリック
  private async cancel(id: number) {
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます"
      )
    ) {
      this.$router.push({ path: `/portal/portal-office-ibow/detail/${id}` });
    }
  }
}
